<template>
    <!-- 전체 콘텐츠 영역 -->
    <div class="main-contents">
        <div class="news_wrap">
          <div class="path">뉴스센터 > 공지사항</div>
          <div class="title_wrap">
            <h2>공지사항</h2>
          </div>
          <!-- list -->
          <div class="news-list">
            <ul>
              <li v-for="(noti, index) in noticeList" :key="noti.newsCenterSeq">
                <a href="javascript:void(0);" @click="goNoticeDetail(noti.newsCenterSeq)">
                  <span class="numb">{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + index+1}}</span>
                  <span class="text"> <span v-if="noti.newNotice=='Y'" class="icon-new">NEW</span>{{noti.newsCenterTitle}}</span>
                  <span class="date">{{noti.newsCenterReg | date('yyyy. MM. dd')}}</span>
                </a>
              </li>
            </ul>
          </div>

          
          <!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>
        </div>
      </div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';
export default {
    components: { pagingComp },
    data() {
        return {
            input : {
                pageIndex: 1,
            },
            noticeList : [],
            pageInfo: {},

        }
    },
    mounted() {
        this.getNoticeList();
    },

    methods: {
        getNoticeList() {
            this.$.httpPost('/api/main/bbs/getNoticeList', this.input)
                .then(res => {
                    this.noticeList = res.data.noticeList;
                    this.pageInfo = res.data.pageInfo;
                }).catch(this.$.httpErrorCommon);
        },

        goNoticeDetail(idx) {
            this.$router.push({name: 'BBS002M02', params:{newsCenterSeq:idx}});
        },

        goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getNoticeList();
		},
    }
}
</script>